import CommunityPage from './CommunityPage.js'; // Import the Leaderboard component
import Leaderboard from './Leaderboard'; // Import the Leaderboard component
import ButtonPage from './ButtonPage.js'; // Import the new ButtonPage component
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'; // Firebase Firestore functions
import React, { useState, useEffect, useContext  } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import SongLyricsPage from './SongLyricsPage'; // Import the new component
import { firestore } from './firebase-config';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import bcrypt from 'bcryptjs';
import { AuthContext, AuthProvider } from './AuthContext'; // Import AuthContext

let globalUsername = 'Guest';
const legends_gig_poster = require('./gig_images/legends_bar_poster.png');
const seven_stars_gig_poster = require('./gig_images/seven_stars_poster.png');
const sumac_centre_poster = require('./gig_images/sumac_centre_poster.png');
const billy_bootleggers_poster = require('./gig_images/billy_bootleggers_poster.png');

function HomePage() {
  const { loggedInUser, login, logout } = useContext(AuthContext); // Access AuthContext
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  // Handle sign-up logic
  const handleSignUp = async () => {
    // Regular expression for valid username (only alphanumeric and underscores)
    const usernameRegex = /^[a-zA-Z0-9_]+$/;
  
    // Regular expression for valid email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    // List of offensive words (this can be expanded)
    const offensiveWords = ['shit', 'fuck', 'dick', 'nigger', 'cunt', 'bitch'];
  
    // Check if username contains any offensive words
    const containsOffensiveWords = offensiveWords.some(word => username.toLowerCase().includes(word.toLowerCase()));
  
    if (containsOffensiveWords) {
      setMessage('Username contains offensive language. Please choose a different one.');
      return;
    }
  
    if (!usernameRegex.test(username)) {
      setMessage('Username can only contain letters, numbers, and underscores.');
      return;
    }
  
    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email address.');
      return;
    }
  
    try {
      // Check if username already exists
      const usernameQuery = query(collection(firestore, 'users'), where('username', '==', username));
      const usernameSnapshot = await getDocs(usernameQuery);
  
      if (!usernameSnapshot.empty) {
        setMessage('Username already exists. Please choose a different one.');
        return;
      }
  
      // Check if email already exists
      const emailQuery = query(collection(firestore, 'users'), where('email', '==', email));
      const emailSnapshot = await getDocs(emailQuery);
  
      if (!emailSnapshot.empty) {
        setMessage('Email is already registered. Please use a different email.');
        return;
      }
  
      // Hash the password
      const hashedPassword = await bcrypt.hash(password, 10);
  
      // Create a reference to the document with the username as the ID
      const docRef = doc(firestore, 'users', username);
  
      // Set the document data
      await setDoc(docRef, {
        username,
        email,
        password: hashedPassword,
        buttonScore: 0,
        rank: 'User',
      });
  
      setMessage('Sign-up successful! You can now log in.');
      setIsLogin(true); // Switch to login mode
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // Handle login logic
  const handleLogin = async () => {
    try {
      const q = query(collection(firestore, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setMessage('User not found.');
        return;
      }
  
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();
      const isPasswordValid = await bcrypt.compare(password, userData.password);
  
      if (isPasswordValid) {
        login({ 
          username: userData.username, 
          email: userData.email, 
          rank: userData.rank // Include rank in context 
        });
        globalUsername = userData.username; // Update the global variable
        localStorage.setItem('globalUsername', userData.username); // Save to localStorage
  
        setUsername(userData.username); // Set username state
        setMessage(`Welcome back, ${userData.username}!`);
      } else {
        setMessage('Invalid password.');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };
  

  // Handle logout
  const handleLogout = () => {
    logout(); // Clear AuthContext
    localStorage.removeItem('globalUsername'); // Remove from localStorage
    globalUsername = 'Guest'; // Reset global variable
    setUsername('Guest'); // Reset username state
    setMessage('Logged out successfully.');
  };

  return (
    <div className="page home-page">
      <h1>Welcome to the Lefthouse Website</h1>
      <p>Have a look around to find information about gig dates, music releases, and more!</p>
      <h2>Bio</h2>
      <p id="aboutusDesc">
        Lefthouse are a Nottinghamshire (UK) based rock band that began in mid-2022, creating and performing music
        somewhere on the spectrum between Post-Punk and Noise Rock.
      </p>
      <p id="aboutusDesc2">
        The band was founded and has always consisted of members Aaron Ashworth (Vocals, Guitar), Freddie Jones (Guitar),
        Zach Bladen (Bass) and Ben Wilson (Drums, Miscellaneous skill haver). Their sound is characterized by punchy,
        droning bass, loud and rhythmic guitars; and are heavily incrusted by symbolic, mellow vocals.
      </p>

      {/* Spotify Embed */}
      <iframe
        src="https://open.spotify.com/embed/track/2KiDqRoIe6UiFiX7uG6reI"
        width="300"
        height="80"
        frameBorder="0"
        allow="encrypted-media"
        title="Spotify player"
      ></iframe>

<div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
        <h1>{isLogin ? 'Login (BETA)' : 'Sign Up (BETA)'}</h1>

        {loggedInUser ? (
          <div>
            <p>
              <strong>Welcome, {loggedInUser.username}!</strong>
            </p>
            <button onClick={handleLogout} style={{ width: '100%', marginBottom: '10px' }}>
              Log Out
            </button>
          </div>
        ) : (
          <div>
            {!isLogin && (
              <div>
                <label>Username:</label>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  style={{ display: 'block', marginBottom: '10px', width: '100%' }}
                />
              </div>
            )}

            <div>
              <label>Email:</label>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ display: 'block', marginBottom: '10px', width: '100%' }}
              />
            </div>

            <div>
              <label>Password:</label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ display: 'block', marginBottom: '20px', width: '100%' }}
              />
            </div>

            {isLogin ? (
              <button onClick={handleLogin} style={{ marginBottom: '10px', width: '100%' }}>
                Log In
              </button>
            ) : (
              <button onClick={handleSignUp} style={{ marginBottom: '10px', width: '100%' }}>
                Sign Up
              </button>
            )}

            <button
              onClick={() => setIsLogin(!isLogin)}
              style={{ display: 'block', marginTop: '10px', width: '100%' }}
            >
              {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Log In'}
            </button>
          </div>
        )}

        {message && <p style={{ color: isLogin ? 'green' : 'blue', marginTop: '20px' }}>{message}</p>}
      </div>
    </div>
  );
}

function GigsPage() {
  const [gigs, setGigs] = useState([]);

  useEffect(() => {
    setGigs(gigsData);
  }, []);

  // Function to format text
  const formatText = (text) => {
    // Replace **text** with <strong>text</strong> and *text* with <em>text</em>
    return text.split(/(\*\*.*?\*\*|\*.*?\*)/g).map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>; // Remove the double asterisks
      } else if (part.startsWith('*') && part.endsWith('*')) {
        return <em key={index}>{part.slice(1, -1)}</em>; // Remove the single asterisks
      }
      return part; // Return the part as is if no formatting
    });
  };

  return (
    <div className="gigs-page" id="gigs">
      <h1 id="gigs_page_title">Lefthouse Gigs</h1>
      <section className="gig-section">
        <h1>Gigography</h1>

        {/* Display gigs in a table */}
        <table className="gig-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Venue</th> 
              <th>Host</th>
              <th>Lineup</th>
            </tr>
          </thead>
          <tbody>
            {gigs.map((gig, index) => (
              <tr key={index}>
                <td>{gig.date}</td>
                <td>{formatText(gig.venue)}</td>
                <td>{formatText(gig.host)}</td>
                <td>{formatText(gig.line_up)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      {/* Embed YouTube Video */}
      <div className="video-wrapper">
  <iframe
    src="https://www.youtube.com/embed/2NlZsHuOP3k" // Replace with your video ID
    width="560"
    height="315"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>
    </div>
  );
}

var notFromLondon = "I'm Not From London"
const gigsData = [
  {
    date: '15/11/2024',
    venue: 'The Victoria Inn, *Derby*',
    host: 'MUSIKBOX',
    line_up: '**Skeeve**, Lefthouse, Hot-Fuzz',
  },
  {
    date: '10/09/2024',
    venue: 'Billy Bootleggers, *Nottingham*',
    host: notFromLondon,
    line_up: '**Unit Six**, Hex Undone, Lefthouse',
  },
  {
    date: '20/07/2024',
    venue: 'The Seven Stars, *Derbyshire*',
    host: 'Self-Hosted',
    line_up: '**Lefthouse**, Vi',

  },
  {
    date: '17/02/2024',
    venue: 'The Seven Stars, *Derbyshire*',
    host: 'Self-Hosted',
    line_up: '**Lefthouse**, Vi',
  },
  {
    date: '01/12/2023',
    venue: 'Legends Sports Bar, *Mansfield*',
    host: 'Thundergroove Promotions',
    line_up: '**Static Attic**, Solomon Sage, Five Second Fuse, Lefthouse'
  },
];
function SocialMediaPage() {
  return (
    <div className="page socialmedia-page">
      <h1>Social Media</h1>
      <p className="inline100">Be sure to follow us on Social Media to stay updated in terms of what we're up to!</p>
      
      {/* Container for social media links */}
      <div 
        className="social-links-container" 
        style={{
          display: 'flex', 
          flexWrap: 'wrap', // Allow wrapping if needed
          justifyContent: 'center', // Center align all links
          gap: '20px', // Space between each link
        }}
      >
        {/* Spotify */}
        <div className="videoLinks" style={{ textAlign: 'center' }}>
          <h4>Follow Us On Spotify</h4>
          <a href="https://open.spotify.com/artist/78wnVBiI1NhjkBjihpJ30s" target="_blank" rel="noopener noreferrer">
            <img
              className="social-image"
              id="spotify-image"
              src={require('./logos/spotify-logo.png')}
              alt="Spotify logo"
              style={{ maxWidth: '100px' }}
            />
          </a>
        </div>

        {/* Instagram */}
        <div className="videoLinks" style={{ textAlign: 'center' }}>
          <h4>Follow Us On Instagram</h4>
          <a href="https://www.instagram.com/lefthouseband/" target="_blank" rel="noopener noreferrer">
            <img
              className="social-image"
              id="instagram_image"
              src={require('./logos/insta-logo.png')}
              alt="Instagram logo"
              style={{ maxWidth: '100px' }}
            />
          </a>
        </div>

        {/* YouTube */}
        <div className="videoLinks" style={{ textAlign: 'center' }}>
          <h4>Follow Us On YouTube</h4>
          <a href="https://www.youtube.com/@lefthouse" target="_blank" rel="noopener noreferrer">
            <img
              className="social-image"
              src={require('./logos/youtube_channel_thumbnail.png')}
              alt="YouTube thumbnail"
              style={{ maxWidth: '100px' }}
            />
          </a>
        </div>

        {/* Facebook */}
        <div className="videoLinks" style={{ textAlign: 'center' }}>
          <h4>Follow Us On Facebook</h4>
          <a href="https://www.facebook.com/profile.php?id=100088426830361" target="_blank" rel="noopener noreferrer">
            <img
              className="social-image"
              id="facebook_image"
              src={require('./logos/facebook-logo.png')}
              alt="Facebook logo"
              style={{ maxWidth: '100px' }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}


function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [username, setUsername] = useState(localStorage.getItem('globalUsername') || 'Guest'); // Load from localStorage
  const [extrasDropdown, setExtrasDropdown] = useState(false);
  // Sync the username with localStorage whenever the username changes
  useEffect(() => {
    const interval = setInterval(() => {
      // Ensure username is synced with globalUsername if it changes
      const storedUsername = localStorage.getItem('globalUsername')  || 'Guest';
      if (username !== storedUsername) {
        setUsername(storedUsername); // Update state to match stored username
      }
    }, 100); // Check periodically (100ms)
    
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [username]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  const toggleExtrasDropdown = () => {
    console.log("Toggling dropdown");
    setExtrasDropdown(!extrasDropdown);
  };
  return (
    <AuthProvider>
      <Router>
        <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
          <div className="above_navigation">
            <img id="lefthouse_banner_logo" src={require('./lefthouse_banner_logo.png')} alt="Lefthouse Logo" />
            <div className="user-info">
              <p>Welcome, {username}!</p>
            </div>
            <div className="media_links">
              <a href="https://www.instagram.com/lefthouseband/" target="_blank" rel="noopener noreferrer">
                <img className="social-image" id="instagram_nav_image" src={require('./logos/insta-logo.png')} alt="Lefthouse Instagram" />
              </a>
              <a href="https://open.spotify.com/artist/78wnVBiI1NhjkBjihpJ30s" target="_blank" rel="noopener noreferrer">
                <img className="social-image" id="spotify_nav_image" src={require('./logos/spotify-logo.png')} alt="Spotify logo" />
              </a>
              <a href="https://www.youtube.com/@lefthouse" target="_blank" rel="noopener noreferrer">
                <img className="social-image" id="youtube_nav_image" src={require('./logos/youtube_channel_thumbnail.png')} alt="YouTube thumbnail" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100088426830361" target="_blank" rel="noopener noreferrer">
                <img className="social-image" id="facebook_nav_image" src={require('./logos/facebook-logo.png')} alt="Facebook logo" />
              </a>
            </div>
          </div>
          <nav className="navigation">
  <NavLink exact to="/" activeClassName="active">/home/</NavLink>
  <NavLink to="/gigs" activeClassName="active">/gigography/</NavLink>

  {/* Extras Dropdown */}
  <div className="dropdown">
    <button 
      onClick={toggleExtrasDropdown} 
      activeClassName="active"
      
    >
      /extras/
    </button>
    {extrasDropdown && (
      <div className="dropdown-menu">
        <NavLink onClick={toggleExtrasDropdown} to="/socialmedia" activeClassName="active">Social Media</NavLink>
        <NavLink onClick={toggleExtrasDropdown} to="/lyrics" activeClassName="active">Song Lyrics</NavLink>
        {/* <NavLink onClick={toggleExtrasDropdown} to="/community" activeClassName="active">Community</NavLink> */}
        {/* <NavLink to="/button" activeClassName="active">Test</NavLink> */}
      </div>
    )}
  </div>
</nav>
          <div className="container">
            <ScrollToTopOnMount />
            <Routes>
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/gigs" element={<GigsPage />} />
              <Route path="/socialmedia" element={<SocialMediaPage />} />
              <Route path="/lyrics" element={<SongLyricsPage />} />
              <Route path="/button" element={<ButtonPage />} /> {/* Add ButtonPage route */}
              <Route path="/community" element={<CommunityPage />} /> {/* Add ButtonPage route */}
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}


export default App;
