import Sidebar from './sidebar'; // Import the Sidebar component
import Leaderboard from './Leaderboard'; // Import the Leaderboard component
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext'; // Import AuthContext

function ButtonGamePage() {
  const { loggedInUser, buttonScore, updateButtonScore, loading } = useContext(AuthContext);
  const [targetPosition, setTargetPosition] = useState({ top: '50%', left: '50%' });
  const [gameActive, setGameActive] = useState(false);
  const [timer, setTimer] = useState(30); // 30 seconds timer

  // Start the game
  const startGame = () => {
    setGameActive(true);
    setTimer(30);
    setTargetPosition(generateRandomPosition());
  };

  // Stop the game
  const endGame = () => {
    setGameActive(false);
    alert(`Game over!`);
  };

  // Move the target to a random position
  const generateRandomPosition = () => {
    const top = Math.random() * 80 + 10; // 10% to 90% (to avoid going off-screen)
    const left = Math.random() * 80 + 10;
    return { top: `${top}%`, left: `${left}%` };
  };

  // Handle target clicks
  const handleTargetClick = () => {
    if (!gameActive) return;

    updateButtonScore(); // Increment the player's score
    setTargetPosition(generateRandomPosition()); // Move the target
  };

  // Countdown timer
  useEffect(() => {
    if (!gameActive) return;

    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          endGame();
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount or game end
  }, [gameActive]);

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar /> {/* Sidebar will take up its fixed width */}
      <div
        className="button-game-page"
        style={{
          flex: 1, // Make this div take up the remaining space
          padding: '20px',
          position: 'relative', // Allow positioning of game elements
        }}
      >
        {loggedInUser ? (
          <div>
            <h1>Hello, {loggedInUser.username}!</h1>
            <p>Your current button score is: {buttonScore}</p>
            <p>Time Left: {timer} seconds</p>

            {!gameActive ? (
              <button
                onClick={startGame}
                style={{
                  padding: '10px 20px',
                  fontSize: '18px',
                  cursor: 'pointer',
                  marginTop: '20px',
                }}
              >
                Start Game
              </button>
            ) : (
              <p>Find and click the circle to increase your score!</p>
            )}

            {gameActive && (
              <div
                onClick={handleTargetClick}
                style={{
                    position: 'absolute',
                    top: targetPosition.top,
                    left: targetPosition.left,
                    width: '50px',
                    height: '50px',
                    backgroundColor: 'black', // Black background
                    border: '2px solid white', // White border
                    borderRadius: '50%', // Rounded into a circle
                    cursor: 'pointer',
                    transition: 'top 0.2s, left 0.2s', // Smooth transition
                  }}
              />
            )}
          </div>
        ) : (
          <p>Please log in to play the game and increase your score.</p>
        )}
        <Leaderboard />
      </div>
    </div>
  );
}

export default ButtonGamePage;
