import React, { createContext, useState, useEffect } from 'react';
import { firestore } from './firebase-config';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [buttonScore, setButtonScore] = useState(0);
  const [rank, setRank] = useState('User');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userData = localStorage.getItem('loggedInUser');
    if (userData) {
      const user = JSON.parse(userData);
      setLoggedInUser(user);
      handleUserOnlineStatus(user.username, true); // Set user online
      fetchUserData(user.username);
    } else {
      setLoading(false);
    }

    // Ensure user is marked offline when they disconnect
    return () => {
      if (loggedInUser?.username) {
        handleUserOnlineStatus(loggedInUser.username, false);
      }
    };
  }, []);

  // Fetch user data including buttonScore and rank
  const fetchUserData = async (username) => {
    try {
      const userRef = doc(firestore, 'users', username);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        setButtonScore(data.buttonScore || 0);
        setRank(data.rank || 'User');
      } else {
        await setDoc(userRef, { buttonScore: 0, rank: 'User', online: false }, { merge: true });
        setButtonScore(0);
        setRank('User');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const updateButtonScore = async () => {
    if (!loggedInUser || !loggedInUser.username) {
      console.error('User UID is not available.');
      return;
    }
  
    try {
      const userRef = doc(firestore, 'users', loggedInUser.username); // Use UID for document reference
      const newButtonScore = buttonScore + 1;
  
      // Update buttonScore in Firestore
      await updateDoc(userRef, { buttonScore: newButtonScore });
      setButtonScore(newButtonScore); // Update the local state
    } catch (error) {
      console.error('Error updating button score:', error);
    }
  };

  // Function to update rank
  const updateRank = async (newRank) => {
    if (!loggedInUser || !loggedInUser.username) {
      console.error('User not logged in.');
      return;
    }

    try {
      const userRef = doc(firestore, 'users', loggedInUser.username);
      await updateDoc(userRef, { rank: newRank });
      setRank(newRank);
    } catch (error) {
      console.error('Error updating rank:', error);
    }
  };

  // Function to manage user's online status
  const handleUserOnlineStatus = async (username, isOnline) => {
    try {
      const userRef = doc(firestore, 'users', username);
      await updateDoc(userRef, { online: isOnline });

      // Handle disconnection if setting online
      if (isOnline) {
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const presenceRef = userRef;
          updateDoc(presenceRef, { online: true });

          // Mark offline on disconnect
          window.addEventListener('beforeunload', async () => {
            await updateDoc(userRef, { online: false });
          });
        }
      }
    } catch (error) {
      console.error('Error updating user online status:', error);
    }
  };

  const login = (user) => {
    setLoggedInUser(user);
    localStorage.setItem('loggedInUser', JSON.stringify(user));
    handleUserOnlineStatus(user.username, true); // Set user online
    fetchUserData(user.username);
  };

  const logout = () => {
    if (loggedInUser?.username) {
      handleUserOnlineStatus(loggedInUser.username, false); // Set user offline
    }
    setLoggedInUser(null);
    setButtonScore(0);
    setRank('User');
    localStorage.removeItem('loggedInUser');
  };

  return (
    <AuthContext.Provider
      value={{
        loggedInUser,
        login,
        logout,
        buttonScore,
        updateRank,
        updateButtonScore,
        rank,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
