import React, { useState, useContext, useEffect } from 'react';
import { firestore } from './firebase-config';
import { collection, addDoc, query, getDocs, orderBy, limit, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { AuthContext } from './AuthContext';
import MarkdownIt from 'markdown-it';
import Sidebar from './sidebar'; // Import Sidebar

const md = new MarkdownIt();
const MAX_CONTENT_LENGTH = 200; // Set the character limit for expansion

function CommunityPage() {
  const { loggedInUser, rank, updateRank } = useContext(AuthContext);
  const [threads, setThreads] = useState([]);
  const [newThread, setNewThread] = useState('');
  const [message, setMessage] = useState('');
  const [newRank, setNewRank] = useState(rank); // Temporary state for rank input

  // Fetch the rank of the user who posted the thread
  const fetchUserRank = async (username) => {
    try {
      const userRef = doc(firestore, 'users', username); // Assuming user data is in the 'users' collection
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        return userDoc.data().rank || 'User'; // Return the rank or default to 'User'
      }
      return 'User'; // Default to 'User' if rank not found
    } catch (error) {
      console.error('Error fetching user rank:', error);
      return 'User'; // Default to 'User' in case of an error
    }
  };

  // Fetch threads from Firestore and include the user rank
  const fetchThreads = async () => {
    const threadsRef = collection(firestore, 'threads');
    const q = query(threadsRef, orderBy('createdAt', 'desc'), limit(10));
    const querySnapshot = await getDocs(q);
    const threadsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch ranks for all threads and their posters
    const updatedThreads = await Promise.all(
      threadsData.map(async (thread) => {
        const rank = await fetchUserRank(thread.username);
        return { ...thread, rank };
      })
    );

    setThreads(updatedThreads);
  };

  // Handle creating a new thread
  const handleCreateThread = async () => {
    if (!newThread.trim()) {
      setMessage('Please enter a thread message.');
      return;
    }

    try {
      if (!loggedInUser) {
        setMessage('You must be logged in to create a thread. Do so on the /home/ page.');
        return;
      }

      const threadsRef = collection(firestore, 'threads');
      await addDoc(threadsRef, {
        content: newThread,
        createdAt: new Date(),
        username: loggedInUser.username,
        userId: loggedInUser.email,
      });

      setNewThread('');
      setMessage('Thread created successfully!');
      fetchThreads();
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // Handle deleting a thread (for Admin only)
  const handleDeleteThread = async (threadId) => {
    if (window.confirm('Are you sure you want to delete this thread?')) {
      try {
        const threadRef = doc(firestore, 'threads', threadId);
        await deleteDoc(threadRef);
        setThreads(threads.filter((thread) => thread.id !== threadId)); // Remove deleted thread from state
        setMessage('Thread deleted successfully!');
      } catch (error) {
        console.error('Error deleting thread:', error);
        setMessage('Error deleting thread.');
      }
    }
  };

  // Handle rank update
  const handleUpdateRank = () => {
    if (newRank !== rank) {
      updateRank(newRank);
      setMessage(`Rank updated to ${newRank}`);
    }
  };

  // Function to toggle expand/collapse of thread content
  const toggleExpand = (threadId) => {
    setThreads((prevThreads) =>
      prevThreads.map((thread) =>
        thread.id === threadId
          ? { ...thread, isExpanded: !thread.isExpanded }
          : thread
      )
    );
  };

  useEffect(() => {
    fetchThreads();
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar /> {/* Add Sidebar */}
      <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
        <h1>Community Page</h1>
        <p>
          ({rank}) {loggedInUser ? loggedInUser.username : 'Guest'}
        </p>

        <div className="post-form">
          <h2>Create a Post</h2>
          <textarea
            placeholder="Write your thread here"
            value={newThread}
            onChange={(e) => setNewThread(e.target.value)}
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <button onClick={handleCreateThread} style={{ width: '100%' }}>
            Create Thread
          </button>
        </div>

        {message && <p style={{ color: 'green', marginTop: '20px' }}>{message}</p>}

        <h2>Posts</h2>
        <div
          style={{
            maxHeight: '400px', // Limit height for scrolling
            overflowY: 'auto', // Enable vertical scroll
            paddingRight: '10px', // Prevent scrollbar overlap
          }}
        >
          {threads.length > 0 ? (
            threads.map((thread) => (
              <div
                key={thread.id}
                style={{
                  marginBottom: '20px',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  borderRadius: '10px',
                  padding: '10px',
                  color: 'white',
                  border: '2px solid white',
                  boxSizing: 'border-box',
                }}
              >
                <h3>
                  ({thread.rank}) {thread.username}
                </h3>
                <div
                  style={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: md.render(
                      thread.content.length > MAX_CONTENT_LENGTH && !thread.isExpanded
                        ? `${thread.content.slice(0, MAX_CONTENT_LENGTH)}...`
                        : thread.content
                    ),
                  }}
                />
                {thread.content.length > MAX_CONTENT_LENGTH && (
                  <button
                    onClick={() => toggleExpand(thread.id)}
                    style={{ marginTop: '10px' }}
                  >
                    {thread.isExpanded ? 'Show Less' : 'Show More'}
                  </button>
                )}
                <p>
                  <small>{new Date(thread.createdAt.seconds * 1000).toLocaleString()}</small>
                </p>

                {/* Show delete button only for Admin */}
                {rank === 'Admin' && (
                  <button
                    onClick={() => handleDeleteThread(thread.id)}
                    style={{ color: 'red', border: '1px solid red', marginTop: '10px' }}
                  >
                    Delete Thread
                  </button>
                )}
              </div>
            ))
          ) : (
            <p>No threads available yet.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommunityPage;
